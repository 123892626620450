import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { getCompetitionDays } from '../store/competitionDay/actions'
import { getCompetitionDay } from '../utils/getCompetitionDay'
import { getTrackings } from '../store/tracking/actions'
import { translateDayName } from '../utils/translateDayName'

const CompetitorStatuses = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const competitionDays = useSelector((state) => state.competitionDay.competitionDays)
    const trackings = useSelector((state) => state.tracking.trackings)

    const [today, setToday] = useState('')

    useEffect(() => {
        dispatch(getCompetitionDays())
    }, [dispatch])

    useEffect(() => {
        const today = getCompetitionDay(competitionDays)
        if (!today) {
            return
        }
        setToday(today)
    }, [competitionDays])

    useEffect(() => {
        if (!today) {
            return
        }
        dispatch(getTrackings(today))
    }, [dispatch, today])

    return (
        <div>

        </div>
    )
}

export default CompetitorStatuses
