import React from 'react'
import { useTranslation } from 'react-i18next'
import Tracking from './Tracking'
import Sponsors from '../components/Sponsors'
import News from './News'
import Footer from './Footer'
import { Container, Row, Col } from 'reactstrap'

const Home = () => {
    const { t } = useTranslation()
    return (
        <div className="homepage">
            <div className="homepage-highlight">
                <div className="homepage-title-box">
                    <h1>
                        Rakovnická dlaždice
                    </h1>
                    <h2>
                       3. 8. – 10. 8. 2024
                    </h2>
                </div>
            </div>
            <div className="homepage-body">
                <Container>
                    <Row>
                        <Col md={8}>
                            <News />
                        </Col>
                        <Col md={4}>
                            <Tracking />
                            <Sponsors />
                        </Col>
                    </Row>
                    <Footer />
                </Container>
            </div>
        </div>
    )
}

export default Home
